import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ButtonBack} from "../components/Buttons";
import * as actions from "../store/actions";
import LoadingSpinner from "../assets/svg/LoadingSpinner";
import {useHistory} from "react-router";
import {InformationCircleIcon, PencilSquareIcon} from "@heroicons/react/24/outline";
import {TrashIcon} from "@heroicons/react/20/solid";
import {Link} from "react-router-dom";
import {Tooltip} from "@mui/material";

const CustomerCompanyInvite = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const companyInvites = useSelector(state => state.customer.companyInvites)
  const companyInvitesLoading = useSelector(state => state.customer.companyInvitesLoading)
  const companyInvitesList = companyInvites?.BusinessCompany?.BusinessUserInvitations || []

  useEffect(() => {
    dispatch(actions.getCustomerCompanyInvite())
  }, [dispatch])


  return (
    <div className="container max-w-screen-lg mx-auto">

      <div className="mt-8 mb-16 mx-4">

        <ButtonBack />

        <div className="text-center text-3xl font-light mt-8">Personen</div>
        <div className="text-center text-md font-light mt-2">Gastnamen und Zugänge deines Unternehmens verwalten.</div>

        <div className="flex justify-center rounded-lg mt-8">

          <table className="table-auto border-collapse text-sm md:text-sm">
            <thead>
            <tr className="tr-class text-secondaryBlue bg-gray-50">
              <th className="th-class">Name</th>
              <th className="th-class">E-Mail</th>
              <th className="th-class">
                <div className="flex">Self-Service <Tooltip title={'Berechtigung zum Buchen/ Stornieren eigener Übernachtungen via MyFlexHome Portal'}>
                  <InformationCircleIcon className="flex-none w-5 h-5 ml-2"/></Tooltip>
                </div>
              </th>
              <th className="th-class">Sprache</th>
              <th className="th-class">Kostenstelle</th>
              <th className="th-class">
                <div className="flex">Rollen <Tooltip title={'Zusätzliche Berechtigungen zur Stellvertretung des Admins'}>
                  <InformationCircleIcon className="flex-none w-5 h-5 ml-2"/></Tooltip>
                </div>
              </th>
              <th className="th-class">Aktionen</th>
            </tr>
            </thead>
            <tbody>
            {companyInvitesList.map(x =>
              <tr key={x.invitationID} className="tr-class">
                <td className="td-class">{x.firstName} {x.lastName}</td>
                <td className="td-class break-all">{x.email}</td>
                <td className="td-class">{x.selfService ? 'ja' : 'nein'}</td>
                <td className="td-class uppercase">{x.language}</td>
                <td className="td-class">{x.costCenter}</td>
                <td className="td-class">
                  {x.deputyBooker ? 'Booker' : ''}
                  {x.deputyBooker && x.deputyAdmin ? <br /> : ''}
                  {x.deputyAdmin ? 'Admin' : ''}
                </td>
                <td className="td-class">
                  <div className="flex">
                    <Link to={'/dashboard/company/invite/edit/'+x.invitationID+'/'}><PencilSquareIcon className="w-6 h-6 hover:text-primary cursor-pointer" title="Bearbeiten"/></Link>
                    &nbsp;&nbsp;
                    <Link to={'/dashboard/company/invite/archive/'+x.invitationID+'/'}><TrashIcon className="w-6 h-6 hover:text-primary cursor-pointer" title="Archivieren" /></Link>
                  </div>
                </td>
              </tr>)}
            </tbody>
          </table>

        </div>
        {companyInvitesLoading
          ? <div className="flex justify-center mt-4"><LoadingSpinner /></div>
          : ''
        }
        {!companyInvitesLoading && companyInvitesList.length === 0
          ? <div className="text-center mt-4 text-gray-500">Aktuell wurden noch niemand eingeladen.</div>
          : ''
        }

        <div className="text-center mt-10">
          <button
            className="btn bg-primary text-white text-lg hover:bg-secondaryBlue rounded p-2 w-60 m-2"
            onClick={() => {history.push('/dashboard/company/invite/add/'); window.scrollTo(0, 0)}}
          >Person hinzufügen</button>
        </div>

      </div>

    </div>
  )
}

export default CustomerCompanyInvite

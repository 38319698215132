import {Listbox, Transition} from '@headlessui/react'
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/24/solid'
import React, {Fragment, useEffect} from "react";
import * as actions from "../store/actions";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router";
import {updateCurrentUrlParams} from "../functions/url";


const CustomerCompanyEmployeeSelection = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  let location = useLocation()
  const searchQuery = new URLSearchParams(location.search)
  const employee = searchQuery.get('selectedUser') || ''

  useEffect(() => {
    dispatch(actions.getCustomerCompanyInvite(employee))
  }, [dispatch, employee])

  const companyInvitesUserList = useSelector(state => state.customer.companyInvitesUserList)
  const companySelectedEmployee = useSelector(state => state.customer.companySelectedEmployee)
  const profile = useSelector(state => state.customer.profile)

  const handleEmployeeChange = (e) => {
    dispatch(actions.updateCustomerBookingReservationDelta([]))
    updateCurrentUrlParams(history, {'selectedUser':e.invitationID})
  }

  return(
    <div className="w-80 md:ml-2">
      <div className="text-gray-500 text-sm">Für wen wird gebucht?</div>
      {companyInvitesUserList.length > 0
        ? <Listbox value={companySelectedEmployee} onChange={handleEmployeeChange}>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default bg-white py-2 pl-3 pr-10 text-left rounded-md border border-gray-200 shadow-sm focus:outline-none focus-visible:border-teal-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary sm:text-sm">
            <span className="block truncate">
              <div className="text-base">{companySelectedEmployee?.firstName} {companySelectedEmployee?.lastName}</div>
              <div className="font-light text-sm text-gray-700">{companySelectedEmployee?.email}</div>
              <div className="font-light text-sm text-gray-700">{profile?.BusinessCompany?.name}</div>
            </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {companyInvitesUserList.map((inv, invIdx) => {

                  return(
                    <Listbox.Option
                      key={invIdx}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active ? 'bg-yellow-50' : 'text-gray-800'
                        }`
                      }
                      value={inv}
                    >
                      {({ selected }) => (
                        <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        <div className="text-base">{inv.firstName} {inv.lastName}</div>
                        <div className="font-light text-sm text-gray-700">{inv.email}</div>
                      </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-yellow-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  )
                })}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
        : <div className="text-gray-400 text-sm border border-gray-200 rounded-md m-1 px-4 py-2 flex justify-between">
          <div>Keine weiteren Personen zur Auswahl</div>
          <div className="pointer-events-none inset-y-0 right-0">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="h-5 w-5 text-gray-400"><path fillRule="evenodd" d="M11.47 4.72a.75.75 0 011.06 0l3.75 3.75a.75.75 0 01-1.06 1.06L12 6.31 8.78 9.53a.75.75 0 01-1.06-1.06l3.75-3.75zm-3.75 9.75a.75.75 0 011.06 0L12 17.69l3.22-3.22a.75.75 0 111.06 1.06l-3.75 3.75a.75.75 0 01-1.06 0l-3.75-3.75a.75.75 0 010-1.06z" clipRule="evenodd"></path></svg>
          </div>
          </div>
      }

    </div>
  )
}

export default CustomerCompanyEmployeeSelection
import React from 'react';
import {useDispatch} from "react-redux";
import * as actions from "../store/actions";
import {useHistory} from "react-router";
import {Controller, useForm} from 'react-hook-form';
import {Box, TextField} from '@mui/material';
import {ButtonBack} from "../components/Buttons";
import {Link} from "react-router-dom";

const CustomerPassword = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  /* react hook form */
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      oldPassword:'',
      newPassword:'',
    }
  })
  const onSubmit = data => {
    dispatch(actions.putPassword(data))
  }
  let password = watch("newPassword", "")

  return (
    <div className="container max-w-screen-lg mx-auto">

      <div className="mt-8 mb-16 mx-4">

        <ButtonBack />

        <div className="text-center text-3xl font-light mt-10">Passwort ändern</div>
        <div className="text-center text-md font-light mt-2">Gibt zur Sicherheit dein altes Passwort ein, um ein Neues wählen zu können.</div>


        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mx-auto p-4 rounded-lg sm:w-112">
            <div>

              <Box
                sx={{
                  textAlign: 'center',
                  '& .MuiTextField-root': {mt:1, p:0, width:'90%'},
                  '& .MuiFormControl-root': {mt:1, p:0, width:'90%'},
                }}
              >

                <div className="mb-3">
                  <Controller
                    name="oldPassword"
                    control={control}
                    rules={{
                      required: 'Bitte gib dein altes Passwort ein, um dich zu authentifizieren',
                    }}
                    render={({ field,fieldState}) =>  (
                      <TextField
                        {...field}
                        label="Altes Passwort"
                        type="password"
                        autoComplete="current-password"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />
                </div>

                <Controller
                  name="newPassword"
                  control={control}
                  rules={{
                    required: 'Bitte wähle ein neues Passwort',
                    minLength: {
                      value: 8,
                      message: 'Bitte wähle ein sicheres neues Passwort >= 8 Zeichen',
                    },
                    maxLength: 99,
                  }}
                  render={({ field,fieldState}) =>  (
                    <TextField
                      {...field}
                      label="Neues Passwort"
                      type="password"
                      autoComplete="new-password"
                      value={field.value}
                      onChange={field.onChange}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      variant="outlined"
                    />
                  )}
                />

                <Controller
                  name="repeatPassword"
                  control={control}
                  rules={{
                    required: 'Bitte wiederhole das neue Passwort',
                    validate: value =>
                      value === password || "Die beiden Passwörter stimmen nicht überein"
                  }}
                  render={({ field,fieldState}) =>  (
                    <TextField
                      {...field}
                      label="Wiederholung neues Passwort"
                      type="password"
                      autoComplete="none"
                      value={field.value}
                      onChange={field.onChange}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      variant="outlined"
                    />
                  )}
                />

                <div className="mt-4 mx-6 text-sm font-light text-gray-500 text-right"><Link to="/reset-password/" className="underline">Altes Passwort vergessen?</Link></div>

              </Box>
            </div>


          </div>
          <div className="text-center mx-auto mt-2">
            <button
              type="submit"
              className="btn bg-primary text-white text-lg hover:bg-secondaryBlue rounded p-2 w-56"
            >
              Änderungen speichern
            </button>
          </div>
          <div className="text-center mx-auto mt-2">
            <button
              className="btn bg-secondaryLightGray text-white text-lg hover:bg-secondaryGray rounded p-2 w-56 m-2"
              onClick={() => {history.push('/dashboard/profile/'); window.scrollTo(0, 0)}}
            >Abbrechen</button>
          </div>
        </form>

      </div>

    </div>
  )
}

export default CustomerPassword

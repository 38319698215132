import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../store/actions";
import {Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography} from '@mui/material';
import {styled} from "@mui/styles";
import dashboard_hotel from '../../src/assets/images/dashboard_hotel.webp';
import dashboard_subscription from '../../src/assets/images/dashboard_subscription.webp';
import dashboard_invoice from '../../src/assets/images/dashboard_invoice.webp';
import dashboard_profile from '../../src/assets/images/dashboard_profile.webp';
import dashboard_findNewHotel from '../../src/assets/images/dashboard_findNewHotel.webp';
import dashboard_company from '../../src/assets/images/dashboard_company.webp';
import dashboard_bookingOverview from '../../src/assets/images/dashboard_bookingOverview.webp';
import {Link} from "react-router-dom";

const Dashboard = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.updateCustomerBookingReservationDelta([]))
  }, [dispatch])

  const StyledCard = styled(Card)(({ theme }) => ({
    width: 260,
    marginRight: 8,
    marginBottom: 20,
    [theme.breakpoints.up('sm')]: {
      width: 240,
      margin: 8,
    }
  }))
  const StyledCardContent = styled(CardContent)(({ theme }) => ({
    '& h2': {
      fontSize: 18,
    },
    height: 100,
    [theme.breakpoints.up('sm')]: {
      '& h2': {
        fontSize: 20,
      },
      height: 110,
    }
  }))
  const StyledCardMedia= styled(CardMedia)(({ theme }) => ({
    height: 80,
    [theme.breakpoints.up('sm')]: {
      height: 100,
    }
  }))

  const profile = useSelector(state => state.customer.profile)
  const accountType = profile.accountType
  const userProduct = profile?.userProduct
  const deputyBooker = profile?.deputyBooker
  const deputyAdmin = profile?.deputyAdmin

  const existingSubscriptions = profile?.BookingSubscriptions?.length > 0
  const firstActiveSubscription = profile?.BookingSubscriptions?.filter(subscription => ['active','upcoming','cancelled','paused'].includes(subscription.status))?.[0]
  const subscriptionID = firstActiveSubscription?.subscriptionID || ''
  const bookingUrl = '/dashboard/booking/' + subscriptionID + (accountType === 'businessUser' ? '/?selectedUser='+profile?.invitationID : '/?selectedUser=admin')
  const findNewHotelUrl = '/search/muenchen/' + (userProduct === 'business' ? '?nights=10&product=business' : '?nights=4')

  return (
    <div className="container max-w-screen-lg mx-auto">

      <div className="mt-16 lg:mt-24 mx-auto">

        {/*
        <div className="text-center bg-yellow-50 p-2 rounded-lg border border-yellow-500 mx-28 mb-8">
          <b className="text-yellow-800">Hinweis:</b> Deinem Account ist noch kein Zahlungsoption hinterlegt. Bitte <Link className="underline">Zahlungsmittel wählen</Link>.
        </div>
        */}

        <div className="text-center text-3xl font-light">MyFlexHome Dashboard</div>
        {userProduct === 'business'
          ? <div className="text-center text-base font-light mt-2">Business Hotel-Abo digital verwalten.</div>
          : <div className="text-center text-base font-light mt-2">Verwalte dein Hotel-Abo 100% digital.</div>
        }

        <div className="max-w-screen-md flex flex-wrap mx-auto mb-16 justify-center mt-14">

          {(accountType === 'standalone' || accountType === 'businessUser' || deputyBooker) && existingSubscriptions
            ? <Link to={bookingUrl}>
                <StyledCard>
                  <CardActionArea>
                    <StyledCardMedia
                      image={dashboard_hotel}
                      title={'Übernachtungen'}
                    />
                    <StyledCardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Übernachtungen
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        Abo-Nächte buchen oder stornieren.
                      </Typography>
                    </StyledCardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="primary">
                      mehr
                    </Button>
                  </CardActions>
                </StyledCard>
              </Link>
            : ''}

          {accountType === 'standalone' && !existingSubscriptions
            ? <Link to={findNewHotelUrl}>
              <StyledCard>
                <CardActionArea>
                  <StyledCardMedia
                    image={dashboard_findNewHotel}
                    title={'Hotel-Abo finden'}
                  />
                  <StyledCardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Hotel-Abo finden
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Aktuell hast du kein aktives Abo.
                      Finde hier das passende Hotel und wähle dein Abo-Paket.
                    </Typography>
                  </StyledCardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary">
                    mehr
                  </Button>
                </CardActions>
              </StyledCard>
            </Link>
            : ''
          }

          {(accountType === 'standalone' && userProduct === 'business') || deputyAdmin || deputyBooker
            ? <Link to={'/dashboard/history/'}>
              <StyledCard>
                <CardActionArea>
                  <StyledCardMedia
                    image={dashboard_bookingOverview}
                    title={'Buchungsübersicht'}
                  />
                  <StyledCardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Buchungsübersicht
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Alle gebuchten Nächte auf einen Blick.
                    </Typography>
                  </StyledCardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary">
                    mehr
                  </Button>
                </CardActions>
              </StyledCard>
            </Link>
            : ''}

          {(accountType === 'standalone' && userProduct === 'business') || deputyAdmin
            ? <Link to={'/dashboard/subscriptions/'}>
                <StyledCard>
                  <CardActionArea>
                    <StyledCardMedia
                      image={dashboard_subscription}
                      title={'Mein Abo'}
                    />
                    <StyledCardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Abo-Übersicht
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                         Details zu allen Business Abos ansehen
                      </Typography>
                    </StyledCardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="primary">
                      mehr
                    </Button>
                  </CardActions>
                </StyledCard>
              </Link>
            : ''
          }

          {accountType === 'standalone' && userProduct !== 'business'
            ? <Link to={'/dashboard/subscriptions/'}>
                <StyledCard>
                  <CardActionArea>
                    <StyledCardMedia
                      image={dashboard_subscription}
                      title={'Mein Abo'}
                    />
                    <StyledCardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Mein Abo
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        Details zu deinem Hotel-Abo ansehen und verwalten (z.B. Zahlungsmittel ändern).
                      </Typography>
                    </StyledCardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="primary">
                      mehr
                    </Button>
                  </CardActions>
                </StyledCard>
              </Link>
            : ''
          }

          {accountType === 'standalone' || deputyAdmin
            ? <Link to={'/dashboard/invoice/'}>
                <StyledCard>
                  <CardActionArea>
                    <StyledCardMedia
                      image={dashboard_invoice}
                      title={'Rechnungen'}
                    />
                    <StyledCardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Rechnungen
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        Alle Rechnungen digital als PDF abrufen.
                      </Typography>
                    </StyledCardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="primary">
                      mehr
                    </Button>
                  </CardActions>
                </StyledCard>
              </Link>
            : ''
          }

          {(accountType === 'standalone' && userProduct === 'business') || deputyAdmin
            ? <Link to={'/dashboard/company/invite/'}>
                <StyledCard>
                  <CardActionArea>
                    <StyledCardMedia
                      image={dashboard_company}
                      title={'Unternehmen'}
                    />
                    <StyledCardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Personen
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        Weitere Personen zu deinem Unternehmensaccount hinzufügen.
                      </Typography>
                    </StyledCardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="primary">
                      mehr
                    </Button>
                  </CardActions>
                </StyledCard>
              </Link>
            : ''
          }

          {accountType === 'standalone'
            ? <Link to={'/dashboard/profile/'}>
              <StyledCard>
                <CardActionArea>
                  <StyledCardMedia
                    image={dashboard_profile}
                    title={'Persönliche Daten'}
                  />
                  <StyledCardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Account
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Kundenprofil anpassen  (z.B. Passwort, Zahlungsdaten, Rechnungsadresse).
                    </Typography>
                  </StyledCardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary">
                    mehr
                  </Button>
                </CardActions>
              </StyledCard>
            </Link>
            : ''
          }

          {accountType === 'businessUser'
            ? <Link to={'/dashboard/company/invite/profile/'}>
              <StyledCard>
                <CardActionArea>
                  <StyledCardMedia
                    image={dashboard_profile}
                    title={'Persönliche Daten'}
                  />
                  <StyledCardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Account
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Benutzerprofil sehen und Passwort ändern
                    </Typography>
                  </StyledCardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary">
                    mehr
                  </Button>
                </CardActions>
              </StyledCard>
            </Link>
            : ''
          }

        </div>
        {/*
          <div><button type="button" onClick={requestNotificationPermission} className="border border-secondaryBlue rounded-lg py-1 px-4 m-2">Request notifications</button></div>
        */}


      </div>

    </div>
  );
}

export default Dashboard

import {Fragment} from 'react'
import {Menu, Transition} from '@headlessui/react'
import {UserCircleIcon, HomeIcon, IdentificationIcon, CubeIcon, ArrowRightCircleIcon, TableCellsIcon, DocumentTextIcon, UserGroupIcon, HomeModernIcon, BookOpenIcon} from '@heroicons/react/24/outline'
import {ChevronDownIcon} from '@heroicons/react/24/solid'
import {useHistory} from "react-router";
import {useSelector} from "react-redux";

const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

const HeaderLoginDropDown = () => {

  const history = useHistory()
  const profile = useSelector(state => state.customer.profile)
  const {firstName='', email=''} = profile
  const greeting = 'Hey '+firstName

  const accountType = profile.accountType
  const userProduct = profile?.userProduct
  const deputyBooker = profile?.deputyBooker
  const deputyAdmin = profile?.deputyAdmin

  const existingSubscriptions = profile?.BookingSubscriptions?.length > 0
  const firstActiveSubscription = profile?.BookingSubscriptions?.filter(subscription => ['active','upcoming','cancelled','paused'].includes(subscription.status))?.[0]
  const subscriptionID = firstActiveSubscription?.subscriptionID || ''
  const bookingUrl = '/dashboard/booking/' + subscriptionID + (accountType === 'businessUser' ? '/?selectedUser='+profile?.invitationID : '/?selectedUser=admin')
  const findNewHotelUrl = '/search/muenchen/' + (userProduct === 'business' ? '?nights=10&product=business' : '?nights=4')

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-200 shadow-sm px-2 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary">
          <UserCircleIcon className="ml-1 h-5 w-5 text-secondaryBlue" aria-hidden="true" />
          <span className="ml-2 hidden sm:block text-secondaryBlue">{firstName ? greeting : email}</span>
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-secondaryBlue" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          <div className="block p-1 sm:hidden text-sm text-gray-600 text-center bg-gray-50">{firstName ? greeting : email}</div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => history.push('/dashboard/')}
                  className={classNames(
                    active ? 'bg-primary text-white' : 'text-gray-700',
                    'w-full px-4 py-2 text-sm flex cursor-pointer'
                  )}
                >
                  <TableCellsIcon className={classNames(
                    active ? 'text-white' : 'text-gray-400',
                    'ml-1 mr-2 h-5 w-5'
                  )} aria-hidden="true" /> Dashboard
                </button>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            {(accountType === 'standalone' || accountType === 'businessUser' || deputyBooker) && existingSubscriptions
                ? <>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => history.push(bookingUrl)}
                          className={classNames(
                            active ? 'bg-primary text-white' : 'text-gray-700',
                            'w-full px-4 py-2 text-sm flex cursor-pointer'
                          )}
                        >
                          <HomeIcon className={classNames(
                            active ? 'text-white' : 'text-primary',
                            'ml-1 mr-2 h-5 w-5'
                          )} aria-hidden="true" /> Übernachtungen
                        </button>
                      )}
                    </Menu.Item>
                  </>
                : ''
            }

            {accountType === 'standalone' && !existingSubscriptions
                ? <>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => history.push(findNewHotelUrl)}
                        className={classNames(
                          active ? 'bg-primary text-white' : 'text-gray-700',
                          'w-full px-4 py-2 text-sm flex cursor-pointer'
                        )}
                      >
                        <HomeModernIcon className={classNames(
                          active ? 'text-white' : 'text-primary',
                          'ml-1 mr-2 h-5 w-5'
                        )} aria-hidden="true" /> Hotel-Abo finden
                      </button>
                    )}
                  </Menu.Item>
                </>
                : ''
            }

            {(accountType === 'standalone' && userProduct === 'business') || deputyAdmin || deputyBooker
              ? <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => history.push('/dashboard/history/')}
                    className={classNames(
                      active ? 'bg-primary text-white' : 'text-gray-700',
                      'w-full px-4 py-2 text-sm flex cursor-pointer'
                    )}
                  >
                    <BookOpenIcon className={classNames(
                      active ? 'text-white' : 'text-primary',
                      'ml-1 mr-2 h-5 w-5'
                    )} aria-hidden="true" /> Buchungsübersicht
                  </button>
                )}
              </Menu.Item>
              : ''
            }

            {(accountType === 'standalone' && userProduct === 'business') || deputyAdmin
              ? <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => history.push('/dashboard/subscriptions/')}
                      className={classNames(
                        active ? 'bg-primary text-white' : 'text-gray-700',
                        'w-full px-4 py-2 text-sm flex cursor-pointer'
                      )}
                    >
                      <CubeIcon className={classNames(
                        active ? 'text-white' : 'text-primary',
                        'ml-1 mr-2 h-5 w-5'
                      )} aria-hidden="true" /> Hotel-Abo
                    </button>
                  )}
                </Menu.Item>
              : ''
            }

            {accountType === 'standalone' && userProduct !== 'business'
              ? <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => history.push('/dashboard/subscriptions/')}
                      className={classNames(
                        active ? 'bg-primary text-white' : 'text-gray-700',
                        'w-full px-4 py-2 text-sm flex cursor-pointer'
                      )}
                    >
                      <CubeIcon className={classNames(
                        active ? 'text-white' : 'text-primary',
                        'ml-1 mr-2 h-5 w-5'
                      )} aria-hidden="true" /> Mein Abo
                    </button>
                  )}
                </Menu.Item>
              : ''
            }

            {accountType === 'standalone' || deputyAdmin
              ? <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => history.push('/dashboard/invoice/')}
                      className={classNames(
                        active ? 'bg-primary text-white' : 'text-gray-700',
                        'w-full px-4 py-2 text-sm flex cursor-pointer'
                      )}
                    >
                      <DocumentTextIcon className={classNames(
                        active ? 'text-white' : 'text-primary',
                        'ml-1 mr-2 h-5 w-5'
                      )} aria-hidden="true" /> Rechnungen
                    </button>
                  )}
                </Menu.Item>
              : ''
            }

            {(accountType === 'standalone' && userProduct === 'business') || deputyAdmin
              ? <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => history.push('/dashboard/company/invite/')}
                      className={classNames(
                        active ? 'bg-primary text-white' : 'text-gray-700',
                        'w-full px-4 py-2 text-sm flex cursor-pointer'
                      )}
                    >
                      <UserGroupIcon className={classNames(
                        active ? 'text-white' : 'text-primary',
                        'ml-1 mr-2 h-5 w-5'
                      )} aria-hidden="true" /> Personen
                    </button>
                  )}
                </Menu.Item>
              : ''
            }

            {accountType === 'standalone'
              ? <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => history.push('/dashboard/profile/')}
                      className={classNames(
                        active ? 'bg-primary text-white' : 'text-gray-700',
                        'w-full px-4 py-2 text-sm flex cursor-pointer'
                      )}
                    >
                      <IdentificationIcon className={classNames(
                        active ? 'text-white' : 'text-primary',
                        'ml-1 mr-2 h-5 w-5'
                      )} aria-hidden="true" /> Account
                    </button>
                  )}
                </Menu.Item>
              : ''
            }

          </div>

          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => history.push('/logout/')}
                  className={classNames(
                    active ? 'bg-primary text-white' : 'text-gray-700',
                    'w-full px-4 py-2 text-sm flex cursor-pointer'
                  )}
                >
                  <ArrowRightCircleIcon className={classNames(
                    active ? 'text-white' : 'text-gray-400',
                    'ml-1 mr-2 h-5 w-5'
                  )} aria-hidden="true" /> Logout
                </button>
              )}
            </Menu.Item>
          </div>

        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default HeaderLoginDropDown
import React from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {ButtonBack} from "../components/Buttons";
import {Link} from "react-router-dom";

const CustomerCompanyInviteProfile = () => {
  const history = useHistory()

  const profile = useSelector(state => state.customer.profile)


  return (
    <div className="container max-w-screen-lg mx-auto">

      <div className="mt-8 mb-16 mx-4">

        <ButtonBack/>

        <div className="text-center text-3xl font-light mt-8">Account</div>
        <div className="text-center text-md font-light mt-2">Benutzerprofil sehen und Passwort ändern</div>

        <div className="flex justify-center rounded-lg mt-8">

          <table className="table-auto border-collapse border border-slate-400 sm:w-112 bg-gray-50">
            <tbody>
            <tr>
              <td className="border border-slate-300 p-3 font-bold">Vorname</td>
              <td className="border border-slate-300 p-3">{profile?.firstName}</td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-3 font-bold">Nachname</td>
              <td className="border border-slate-300 p-3">{profile?.lastName}</td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-3 font-bold">E-Mail</td>
              <td className="border border-slate-300 p-3">{profile?.email}</td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-3 font-bold">Firma</td>
              <td className="border border-slate-300 p-3">{profile?.BusinessCompany?.name}</td>
            </tr>
            <tr>
              <td className="border border-slate-300 p-3 font-bold">Passwort</td>
              <td className="border border-slate-300 p-3">******** <br/>
                <Link to="/dashboard/password/"
                      className="border border-primary bg-blue-50 hover:bg-blue-100 rounded-md p-2 my-1">Passwort
                  ändern</Link>
              </td>
            </tr>
            </tbody>
          </table>

        </div>

      </div>

    </div>
  )
}

export default CustomerCompanyInviteProfile
